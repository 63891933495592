import "./style.css";

function Pulse() {
  return (
    <div className="pulseFrame">
      <iframe
        className="pulse"
        title="Pulse"
        src="https://pulse.zerodha.com/"
      ></iframe>
    </div>
  );
}

export default Pulse;
