import "./style.css";

function Heatmap() {
  return (
    <div className="kiteFrame">
      <iframe
        className="pulse"
        title="heatmap"
        src="https://technicals.zerodha.com/dashboard"
      ></iframe>
    </div>
  );
}

export default Heatmap;
