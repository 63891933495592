import { useState } from "react";
import StockPercentageChart from "./StackedBar";
import Heatmap from "../heatmap";
import Pulse from "../pulse";
import "./style.css";

function Chart() {
  const [current, setCurrent] = useState("Options");

  // Define a function to render the iframe for external sites
  const renderIframe = (src, title) => (
    <div className="sensibull">
      <iframe src={src} title={title}></iframe>
    </div>
  );

  const renderCharts = () => {
    switch (current) {
      case "Options":
        return (
          <>
            <StockPercentageChart indexType={"NIFTY"} />
            <StockPercentageChart indexType={"BANKNIFTY"} />
          </>
        );
      case "Groww":
        return (
          <div className="groww">
            {renderIframe(
              "https://groww.in/charts/indices/nifty",
              "Groww Nifty"
            )}
            {renderIframe(
              "https://groww.in/charts/indices/nifty-bank",
              "Groww Nifty Bank"
            )}
          </div>
        );
      case "Sensibull":
        return (
          <div className="sensibull">
            <iframe
              src="https://web.sensibull.com/open-interest/oi-change-vs-strike?tradingsymbol=NIFTY"
              title="Sensibull1"
            ></iframe>
          </div>
        );
      case "Technicals":
        return <Heatmap />;
      case "Pulse":
        return <Pulse />;
      case "Youtube":
        return renderIframe("https://www.youtube.com", "Youtube");
      case "Twitter":
        return renderIframe("https://x.com/", "Twitter");
      case "ChatGPT":
        return renderIframe("https://chatgpt.com/", "ChatGPT");
      case "Onrender":
        return renderIframe(
          "https://dashboard.render.com/project/prj-crr5ocd6l47c73cdgv1g",
          "Onrender"
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-container">
      <div className="user-sub-container">
        <div className="order-sub-container">
          <div className="users-list">
            {[
              "Options",
              "Groww",
              "Sensibull",
              "Technicals",
              "Pulse",
              "Youtube",
              "Twitter",
              "ChatGPT",
              "Onrender",
            ].map((data) => (
              <button
                key={data}
                className={`custom ${current === data ? "activeButton" : ""}`}
                onClick={() => setCurrent(data)}
              >
                {data}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="chart_data">{renderCharts()}</div>
    </div>
  );
}

export default Chart;
