import { Button, Table, Modal, Form } from "antd";
import "./style.css";
import { useEffect, useState } from "react";
import {
  listOrders,
  listUsers,
  createOrders,
  deleteOrders,
} from "../../utils/api";
import C from "./constant";
import CreateForm from "./forms/createform";

function Orders() {
  const [currentUser, setCurrentUser] = useState(null);
  const [user, setUsers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    async function fetch() {
      const users = await listUsers();
      setUsers(users);
      setCurrentUser(users?.[0]?._id);
    }
    fetch();
  }, []);

  useEffect(() => {
    async function fetch() {
      const orders = await listOrders(currentUser);
      setLoading(false);
      setOrders(orders);
    }
    fetch();
  }, [currentUser]);

  const handleSubmit = async (payload) => {
    setLoading(true);
    await createOrders({ userId: currentUser, ...payload });
    const orders = await listOrders(currentUser);
    form.resetFields();
    setOrders(orders);
    setShow(false);
    setLoading(false);
  };

  const handleEdit = (id) => {
    form.setFieldsValue(orders.find((i) => i._id === id));
    setShow(true);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteOrders(id);
    const orders = await listOrders(currentUser);
    setOrders(orders);
    setLoading(false);
  };

  return (
    <div className="order-container">
      <div className="order-sub-container">
        <div className="users-list">
          {user?.map((data) => {
            return (
              <button
                className={`custom ${
                  currentUser === data._id ? "activeButton" : ""
                }`}
                onClick={() => setCurrentUser(data._id)}
              >
                {data.name}
              </button>
            );
          })}
        </div>
        <div className="order-list">
          <Button
            type="primary"
            className="create"
            onClick={() => setShow(true)}
          >
            Create Order
          </Button>
        </div>
      </div>
      <div className="table-content">
        <Table
          rowKey={"_id"}
          loading={loading}
          className="table"
          dataSource={orders}
          columns={C.column(handleEdit, handleDelete)}
          pagination={false}
          scroll={
            orders?.length > 10
              ? {
                  x: "max-content",
                  y: 580, // Set the vertical scroll height as required
                }
              : {
                  x: "max-content",
                }
          }
        />
        <Modal
          title={"Order Form"}
          open={show}
          onOk={() => form.submit()}
          onCancel={() => {
            setShow(false);
            form.resetFields();
          }}
          destroyOnClose
        >
          <div className="order-form-container">
            <CreateForm form={form} handleSubmit={handleSubmit} />
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Orders;
