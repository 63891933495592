import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { getIntialData } from "../../utils/api";
// Register necessary components with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin // Register the zoom plugin
);

const StockVarianceChart = ({ indexType }) => {
  // Candle data with variance values and colors

  const [candles, setCandles] = useState([]);

  useEffect(() => {
    const eventSource = new EventSource(process.env.REACT_APP_PL_SERVER);
    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data?.graph) {
        const { label, ...rest } = data.graph;
        setCandles((prevCandles) => {
          const updatedCandles = [
            ...prevCandles,
            {
              label: new Date(label)
                .toLocaleTimeString()
                .split(":")
                .slice(0, -1)
                .join(":"),
              ...rest,
            },
          ];
          // Keep only the last 30 candles
          return updatedCandles;
        });
      }
    };

    return () => {
      eventSource.close();
    };
  }, []);

  useEffect(() => {
    const fetch = async () => {
      const data = await getIntialData();
      console.log(data);
      setCandles(
        data?.data?.map((ele) => {
          const { label, ...rest } = ele;
          return {
            label: new Date(label)
              .toLocaleTimeString()
              .split(":")
              .slice(0, -1)
              .join(":"),
            ...rest,
          };
        })
      );
    };
    fetch();
  }, [indexType]);

  // Extract unique stock names across all candles
  const allStocks = [
    ...new Set(
      candles
        ?.filter((c) => c?.[indexType])
        ?.flatMap((candle) => Object?.keys(candle?.[indexType]))
    ),
  ];

  // Generate datasets for each stock
  // Generate datasets for each stock
  const datasets = allStocks?.map((stock) => {
    const stockData = candles?.map(
      (candle) => candle?.[indexType]?.[stock]?.value || 0 // Default to 0 if stock is not in the candle
    );
    const backgroundColor = candles?.map((candle) => {
      console.log(candle?.[indexType]?.[stock]?.signal);
      return candle?.[indexType]?.[stock]?.signal === "SELL"
        ? "red"
        : candle?.[indexType]?.[stock]?.signal === "BUY"
        ? "#3ba407"
        : "blue";
    });
    const borderColor = candles?.map((candle) => "white");

    return {
      label: stock,
      data: stockData,
      backgroundColor,
      borderColor, // Add border color
      borderWidth: 0.5, // Set border width
    };
  });

  // Chart data
  const data = {
    labels: candles?.map((candle) => candle.label), // Candle labels
    datasets,
  };

  // Chart options with zoom and pan
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false, // Hide the legend
      },
      title: {
        display: true,
        text: `${indexType} Option Variance Movements`,
      },
      tooltip: {
        callbacks: {
          label: function (i) {
            return i.dataset.label;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true, // Enable panning
          mode: "x", // Allow horizontal panning
        },
        zoom: {
          wheel: {
            enabled: true, // Disable zooming
          },
          pinch: {
            enabled: false, // Disable pinch zooming
          },
          mode: "x", // Restrict to the x-axis
        },
      },
    },

    scales: {
      x: {
        stacked: true, // Enable stacking on the x-axis
        min: candles.length - 15, // Start from the first candle
        max: candles.length, // Show only 30 candles initially
        title: {
          display: true,
          text: "Candles",
        },
      },
      y: {
        stacked: true, // Enable stacking on the y-axis
        title: {
          display: true,
          text: "Variance",
        },
      },
    },
  };

  return <Bar height={90} data={data} options={options} />;
};

export default StockVarianceChart;
